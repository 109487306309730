<template>
  <div id="mint">
    <div class="title">Create new moment</div>
    <div class="uploadNotes">
      File types supported: JPG, PNG, SVG
      <span>Max size: 5 MB<span class="required">*</span></span>
    </div>
    <div class="uploadImg">
      <el-upload
        :class="{ hide: hideUpload }"
        ref="my-upload"
        :action="baseUrl"
        list-type="picture-card"
        :file-list="fileList"
        :on-change="handleChange"
        :before-upload="logoBeforeUpload"
        :on-remove="handleRemove"
        :on-success="logoHandleSuccess"
      >
        <i class="el-icon-picture"></i>
      </el-upload>
    </div>
    <div class="form_view">
      <div class="form_view_title">Name<span>*</span></div>
      <div class="form_view_input">
        <input
          type="text"
          v-model="uploadtextQuery.title"
          placeholder="Moment Name"
        />
      </div>
    </div>
    <div class="form_view">
      <div class="form_view_title">Description<span>*</span></div>
      <div class="form_view_input">
        <textarea
          v-model="uploadtextQuery.description"
          placeholder="Provide a detailed description of your item."
        ></textarea>
      </div>
    </div>
    <div class="form_view">
      <div class="form_view_title">Date of occurrence</div>
      <div class="form_view_input">
        <el-date-picker
          v-model="uploadtextQuery.date"
          :clearable="false"
          type="date"
        ></el-date-picker>
      </div>
    </div>
    <div class="form_view">
      <div class="form_view_title">Information sources</div>
      <div class="form_view_information">
        <div
          class="form_view_information_item"
          :class="{
            informationCheck:
              uploadtextQuery.inforMationSources.type === 'Credible news',
          }"
          @click="uploadtextQuery.inforMationSources.type = 'Credible news'"
        >
          <div class="information_icon"><i class="fa fa-newspaper-o"></i></div>
          <div class="information_text">Credible news</div>
        </div>
        <div
          class="form_view_information_item"
          :class="{
            informationCheck:
              uploadtextQuery.inforMationSources.type === 'Personal experience',
          }"
          @click="
            uploadtextQuery.inforMationSources.type = 'Personal experience'
          "
        >
          <div class="information_icon"><i class="el-icon-view"></i></div>
          <div class="information_text">Personal experience</div>
        </div>
        <div
          class="form_view_information_item"
          :class="{
            informationCheck:
              uploadtextQuery.inforMationSources.type === 'other',
          }"
          @click="uploadtextQuery.inforMationSources.type = 'other'"
        >
          <div class="information_icon"><i class="fa fa-hand-paper-o"></i></div>
          <div class="information_text">other</div>
        </div>
      </div>
      <div class="form_view_input">
        <input
          v-if="uploadtextQuery.inforMationSources.type === 'Credible news'"
          type="text"
          v-model="uploadtextQuery.inforMationSources.value"
          placeholder="https://yoursite.io/time/123"
        />
        <textarea
          v-else
          v-model="uploadtextQuery.inforMationSources.value"
        ></textarea>
      </div>
    </div>
    <div class="form_view locationView">
      <div class="form_view_title">Occurred Location</div>
      <div class="form_view_input1">
        <input
          type="text"
          v-model="uploadtextQuery.occurredLocation"
          placeholder="Get current location"
        />
        <div class="locationClick" :class="{ locationTrue: locationState }">
          <i class="fa fa-location-arrow"></i>
        </div>
        <!--  @click="locationState=true" -->
      </div>
    </div>
    <div class="form_view tagView">
      <div class="form_view_title">Tag</div>
      <div class="form_view_introduce">
        Tagging makes it easier for people to retrieve your NFT
      </div>
      <div class="form_view_tagSingleChoice">
        <div
          class="tagSingleChoice_li"
          v-for="(item, index) in tagList"
          :key="index"
          :class="{
            tagSingleChoice_liTrue: uploadtextQuery.tag.indexOf(item) !== -1,
          }"
          @click="selectTag(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="form_view blockchainView">
      <div class="form_view_title">Blockchain</div>
      <div class="Blockchain_select">
        <div class="Blockchain_select_left">
          <template>
            <div class="blockchainIcon"><img :src="chainImg[chainId]" /></div>
            <div class="blockchainName">{{ chainName[chainId] }}</div>
          </template>
        </div>
        <div class="Blockchain_select_right">
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
    </div>
    <div class="mintCreate_button">
      <button
        :disabled="
          operationLoading ||
          uploadImg === '' ||
          uploadtextQuery.description === '' ||
          uploadtextQuery.title === ''
        "
        :class="{
          mintCreate_button_true:
            uploadImg !== '' &&
            uploadtextQuery.description !== '' &&
            uploadtextQuery.title !== '',
        }"
        @click="mintHandle"
      >
        <span v-if="operationLoading" class="loading_ani"
          >Loading<span class="dotting"></span
        ></span>
        <span v-else>Create</span>
      </button>
    </div>
    <el-dialog
      title="Mint succeed"
      :visible.sync="showShareDialog"
      :modal-append-to-body="true"
      append-to-body
      custom-class="share-dialog"
      :close-on-click-modal="false"
      :before-close="onCloseDialog"
    >
      <a id="link"></a>
      <div class="canvas_box_outer">
        <div id="canvas_box" class="canvas-box">
          <div class="img-box">
            <img :src="data_img" alt="" />
          </div>
          <div class="text-box">
            {{ uploadtextQuery.title.slice(0, 30) }}
            <template v-if="uploadtextQuery.title.length > 30">...</template>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button class="btn1" @click="viewItem">View item</el-button>
        <el-button
          class="btn2"
          :loading="uploadShareTwitterLoading"
          @click="resetForm"
        >
          <a :href="shareTwitterHref" target="_blank">
            <img src="@/assets/imgs/mint/3.svg" alt="" />
            <span class="text">Share</span>
          </a>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getChainIdNetwork } from "../../web3/common/wallet";

const wallet = require("../../web3/common/wallet.js");
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721";
import moment from "moment";
import { chainName, chainImg } from "../../web3/web3Config";
const address = require("../../web3/web3Config.js");

import html2canvas from "html2canvas";

export default {
  name: "Mint",
  inject: ["reload"],
  data() {
    return {
      chainImg: chainImg,
      base_url1: "https://aapi.historydao.io",
      base_url2: "https://aapi.historydao.io",
      uploadShareTwitterLoading: false,
      shareTwitterHref: "",
      showShareDialog: false,
      data_img: "",
      locationState: false,
      tagList: [
        "Business",
        "Entertainment",
        "Health",
        "Style",
        "Travel",
        "Sports",
      ],
      // baseUrl: 'https://api.blockhistory.io/history/uploadfile',
      baseUrl: "https://aapi.historydao.io/history/uploadfile",
      baseUrlData: "",
      file: {},
      fileList: [],
      uploadImg: "",
      hideUpload: false,

      operationLoading: false,
      uploadtextQuery: {
        title: "",
        description: "",
        fileHash: "",
        date: "",
        inforMationSources: {
          type: "Credible news",
          value: "",
        },
        occurredLocation: "",
        tag: "",
        blockChain: "",
      },
      minNewHistoryQuery: {
        admin: "",
        eventid: "",
        totalmintfee: "",
        title: "",
        texthash: "",
        contentdomain: "",
        firstMintTime: "",
        tokenid: "",
        description: "",
        filehash: "",
        filename: "",
        state: "",
        type: "",
        price: "",
        createtime: "",
        network: "",
        contractAddress: "",
      },

      chainName: chainName,
      chainId: "",
    };
  },
  computed: {
    mapState() {
      return this.$store.getters.walletInfo;
    },
  },
  watch: {
    "$store.getters.initStatusComplete": {
      immediate: true,
      handler(flag) {
        if (flag) {
          this.init();
        }
      },
    },
    showShareDialog(val) {
      if (val) {
        this.$nextTick(() => {
          // this.onShare();
        });
      }
    },
  },
  mounted() {},
  methods: {
    async init() {
      this.baseUrlData = localStorage.getItem("baseUrl");
      let res = "";
      if (this.mapState.chainId) {
        res = this.mapState.chainId;
      } else {
        res = await getChainIdNetwork();
      }
      this.chainId = res;
      this.minNewHistoryQuery.contractAddress =
        address.address[this.chainName[res]].HistoryERC721;
    },

    handleChange(file, fileList) {
      fileList.length >= 1
        ? (this.hideUpload = true)
        : (this.hideUpload = false);
    },
    handleRemove(file, fileList) {
      fileList.length >= 1
        ? (this.hideUpload = true)
        : (this.hideUpload = false);
      this.uploadImg = "";
      this.uploadtextQuery.fileHash = "";
    },
    logoBeforeUpload(file) {
      console.log(file,'===')
      this.file = file;
      let reads = new FileReader();
      reads.readAsDataURL(file);
      let _this = this;
      reads.onload = function () {
        _this.data_img = this.result;
      };

      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error(
          "The size of the uploaded picture cannot exceed 100MB!"
        );
      }
      return isLt2M;
    },
    logoHandleSuccess(response) {
      this.uploadImg = response.result.awsUrl;
      this.uploadtextQuery.fileHash = response.result.hash;
      this.minNewHistoryQuery.filehash = response.result.hash;
      this.minNewHistoryQuery.filename = response.result.newfileName;
      // if(response && response.code === '200'){
      //   this.$uploadFileQn.uploadFileQN(response.result,this.file,["image/png", "image/jpeg", "image/gif"],
      //       response.result.newfileName,this.logoCallbackFuProgress(response.result,"logo"));
      // }
    },
    //success
    // logoCallbackFuProgress(res){
    //   if(res.newfileName && res.upToken){
    //     // let imgUrl = '//cdn.encentive.io/' + res.newfileName + `?v=${new Date().getTime()}`;
    //     let imgUrl = `https://cdn.blockhistory.io/${res.newfileName}`;
    //     var imgInterval = setInterval(()=>{
    //       var ImgObj=new Image();
    //       ImgObj.src= imgUrl;
    //       if(ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
    //         this.uploading = false;
    //         this.uploadImg = imgUrl;
    //         this.uploadtextQuery.fileHash = res.hash;
    //         this.minNewHistoryQuery.filehash = res.hash;
    //         this.minNewHistoryQuery.filename = res.newfileName;
    //         clearInterval(imgInterval);
    //       }
    //     },100)
    //   }
    // },

    selectTag(value) {
      if (this.uploadtextQuery.tag.indexOf(value) !== -1) {
        var tagText = "";
        tagText = this.uploadtextQuery.tag.split(",");
        tagText.forEach((item, index) => {
          if (item === "") {
            tagText.splice(index, 1);
          }
          if (value === item) {
            tagText.splice(index, 1);
          }
        });
        this.uploadtextQuery.tag = tagText.toString();
      } else {
        this.uploadtextQuery.tag = this.uploadtextQuery.tag + "," + value;
      }
    },

    mintHandle() {
      if (
        this.uploadtextQuery.description !== "" &&
        this.uploadtextQuery.description !== "" &&
        this.uploadImg !== ""
      ) {
        this.operationLoading = true;
        this.uploadtextRequire();
      }
    },
    uploadtextRequire() {
      if (this.mapState.chainId) {
        //obtain wallet address
        wallet.getWalletInfo().then((info) => {
          this.minNewHistoryQuery.admin = info.address.toLowerCase();
          this.minNewHistoryQuery.network = info.network;
          this.mintServer();
        });
      }
    },
    mintServer() {
      let _this = this;

      // if (this.chainId == 1) {
      //     this.uploadtextQuery.blockChain = "Ethereum";
      // }
      // if (this.chainId == 56) {
      //     this.uploadtextQuery.blockChain = "BNB Chain";
      // }
      this.uploadtextQuery.blockChain = chainName[this.chainId];
      let params = this.uploadtextQuery;
      params.date !== "" ? (params.date = params.date.getTime()) : "";
      let ctr = createHistoryV1ERC721();
      this.$axios
        .post(this.baseUrlData + this.$api.post_uploadtext, params)
        .then((res) => {
          _this.minNewHistoryQuery.texthash = res.result.textHash;
          //obtain tokenid
          ctr
            .mintNewHistoryReport(
              res.result.textHash,
              this.uploadtextQuery.title
            )
            .then((res2) => {
              // 特殊处理zk链
              if (
                _this.mapState.chainId == 280 ||
                _this.mapState.chainId == 324
              ) {
                res2.forEach((item) => {
                  if (item.data == "0x") {
                    return (_this.minNewHistoryQuery.tokenid = String(
                      item.args[2]
                    ));
                  }
                });
              } else {
                _this.minNewHistoryQuery.tokenid = String(res2[0].args[2]);
              }
              //obtain eventId
              ctr
                .tokenEventId(this.minNewHistoryQuery.tokenid)
                .then((eventId) => {
                  _this.minNewHistoryQuery.eventid = Number(eventId);
                  ctr.eventData(Number(eventId)).then((value) => {
                    _this.minNewHistoryQuery.totalmintfee = Number(value[0]);
                    _this.minNewHistoryQuery.contentdomain = value[3];
                    _this.minNewHistoryQuery.firstMintTime = moment(
                      Number(value.firstMintTime) * 1000
                    ).format("YYYY-MM-DD, HH:mm:ss");
                    _this.minNewHistoryQuery.title =
                      _this.uploadtextQuery.title;
                    _this.minNewHistoryQuery.description =
                      _this.uploadtextQuery.description.replace('"', '"');
                    // //server
                    let params2 = this.minNewHistoryQuery;
                    //format description
                    _this.$axios
                      .post(
                        _this.baseUrlData + _this.$api.post_minNewHistory,
                        params2
                      )
                      .then(() => {
                        _this.showShareDialog = true;
                        _this.shareTwitterHref = `https://twitter.com/intent/tweet?text=${_this.minNewHistoryQuery.title}&url=${_this.base_url2}/history/sharetwitter?tokenid=${_this.minNewHistoryQuery.tokenid}`;
                      });
                  });
                });
            })
            .catch(() => {
              this.operationLoading = false;
            });
        })
        .catch((e) => {
          console.log("Approve Failed:", e);
        });
    },
    clearForm() {
      this.operationLoading = false;
      this.uploadtextQuery = {
        title: "",
        description: "",
        fileHash: "",
        date: "",
        inforMationSources: {
          type: "Credible news",
          value: "",
        },
        occurredLocation: "",
        tag: "Business",
        blockChain: chainName[this.chainId],
      };

      // if (this.chainId == 56) {
      //     this.uploadtextQuery.blockChain = "BNB Chain";
      // }

      this.minNewHistoryQuery = {
        admin: "",
        eventid: "",
        totalmintfee: "",
        title: "",
        texthash: "",
        contentdomain: "",
        firstMintTime: "",
        tokenid: "",
        description: "",
        filehash: "",
        filename: "",
        state: "",
        type: "",
        price: "",
        createtime: "",
      };
      this.uploadImg = "";
    },

    onCloseDialog(done) {
      this.clearForm();
      done();
    },
    viewItem() {
      this.resetForm();
      this.$router.push({ path: "/myNFTs" });
    },
    resetForm() {
      this.showShareDialog = false;
      this.clearForm();
    },
    onShare() {
      this.shareTwitterHref = `https://twitter.com/intent/tweet?text=${this.minNewHistoryQuery.title}&url=${this.base_url2}/history/sharetwitter?tokenid=${this.minNewHistoryQuery.tokenid}`;
      this.uploadShareTwitterLoading = true;

      setTimeout(() => {
        let imgName = this.minNewHistoryQuery.tokenid + ".png";
        // return new Promise((resolve, reject) => {
        let _this = this;
        html2canvas(document.querySelector("#canvas_box"), {
          allowTaint: true,
        }).then((canvas) => {
          var image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

          function dataURLtoFile(dataurl, imgName) {
            //将base64转换为文件
            var arr = dataurl.split(","),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], imgName, { type: mime });
          }

          let file = dataURLtoFile(image, imgName);
          var data = new FormData();
          data.append("file", file, imgName);
          var xhr = new XMLHttpRequest();
          // xhr.withCredentials = true;
          xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
              console.log(this.responseText);
              _this.uploadShareTwitterLoading = false;
            }
          });
          // xhr.open("POST", "https://api.blockhistory.io/history/uploadaws");
          xhr.open("POST", `${_this.base_url1}/history/uploadaws`);
          xhr.send(data);
          // this.showShareDialog = false;
          // var link = document.getElementById("link");
          // console.log(link);
          // link.setAttribute("download", "海报.png");
          // link.setAttribute("href", canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
          // link.click();
        });
        // })
      }, 200);
      //
    },
  },
};
</script>
<style lang="scss" src="../../assets/css/mintPage.scss" scoped></style>
<style lang="scss">
.share-dialog {
  width: 600px;
  height: auto;
  background: #f7f8f9;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 32px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/imgs/mint/bg.png) no-repeat top center;
    background-size: 600px 580px;
  }

  .el-dialog__header {
    height: 66px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
    z-index: 1;

    .el-dialog__title {
      /*font-family: 'Poppins';*/
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      /* identical to box height, or 110% */
      text-align: center;
      color: #04111d;
    }

    .el-dialog__headerbtn {
      top: 28px;
      right: 25px;
      width: 24px;
      height: 24px;
      background: url(../../assets/imgs/mint/close.svg) no-repeat center center;

      i {
        display: none;
      }
    }
  }

  .el-dialog__body {
    position: relative;
    z-index: 1;
    padding: 0;

    .canvas_box_outer {
      width: 400px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      overflow: hidden;
      margin: 0 auto 32px;
    }

    .canvas-box {
      width: 400px;
      height: auto;
      background: #ffffff;
      padding: 94px 0 44px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 360px;
        height: 52px;
        top: 20px;
        left: 20px;
        background: url(../../assets/imgs/mint/1.svg) no-repeat top center;
        background-size: 360px 52px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 360px;
        height: 52px;
        bottom: 20px;
        left: 20px;
        background: url(../../assets/imgs/mint/2.svg) no-repeat top center;
        background-size: 360px 52px;
      }

      .img-logo {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img-box {
        width: 320px;
        max-height: 334px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 24px;
        height: auto;
        overflow: hidden;

        // border: 1px solid #000;
        img {
          max-width: 100%;
          height: auto;
          // max-height: 334px;
        }
      }

      .text-box {
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */
        text-align: center;
        color: #000000;
        width: 374px;
        margin: 0 auto;
        height: 24px;
      }
    }

    .btn-box {
      display: flex;
      align-content: center;
      justify-content: center;

      .btn1 {
        border: 0;
        width: 130px;
        height: 50px;

        background: #d6bc83;
        mix-blend-mode: normal;
        border-radius: 10px;
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #ffffff;
        padding: 0;
      }

      .btn2 {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 130px;
        height: 50px;

        background: #fdfcf9;
        mix-blend-mode: normal;
        border: 2px solid #d6bc83;
        border-radius: 10px;
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #d6bc83;

        img {
          margin-right: 12px;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #d6bc83;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 1125px) {
  .share-dialog {
    width: 17.66405rem;
    max-height: 26.57287rem;
    background: #ffffff;
    border-radius: 0.6144rem;
    padding-bottom: 0.8192rem;

    &:before {
      display: none;
    }

    .el-dialog__header {
      height: 3.32801rem;

      .el-dialog__title {
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 500;
        font-size: 0.9216rem;
        line-height: 1.1264rem;
        /* identical to box height, or 122% */

        text-align: center;

        color: #353840;
      }

      .el-dialog__headerbtn {
        right: 0.768rem;
        top: 1.2288rem;
        width: 1.024rem;
        height: 1.024rem;

        i {
        }
      }
    }

    .el-dialog__body {
      .canvas_box_outer {
        width: 16.43524rem;
        margin: 0 auto;
        border-radius: 0.3072rem;
        box-shadow: none;
      }

      .canvas-box {
        width: 16.43524rem;
        max-height: 17.15204rem;
        padding: 0;
        margin: 0 auto;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }

        .img-logo {
        }

        .img-box {
          width: 100%;
          max-height: 17.15204rem;
          border-radius: 0.3072rem;
          overflow: hidden;
          margin: 0;

          img {
          }
        }

        .text-box {
          /*font-family: 'Poppins';*/
          font-style: normal;
          font-weight: 400;
          font-size: 0.7168rem;
          line-height: 1.0752rem;
          /* identical to box height */
          text-align: center;
          color: #000000;
          padding: 0.8192rem 0;
          width: 100%;
          height: auto;
        }
      }

      .btn-box {
        .btn1,
        .btn2 {
          width: 8.08962rem;
          height: 2.56001rem;
          border-radius: 0.3072rem;

          /*font-family: 'Poppins';*/
          font-style: normal;
          font-weight: 500;
          font-size: 0.7168rem;
          line-height: 1.0752rem;
          /* identical to box height */
          text-align: center;
          color: #ffffff;
        }

        .btn2 {
          border-width: 1px;

          img {
            width: 1.2288rem;
            height: 1.2288rem;
            margin-right: 0.4096rem;
          }

          span,
          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              width: auto;
            }
          }

          a {
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
  #mint {
    margin: 3.68641rem 0 0;
    padding: 1.3312rem 0.768rem;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 0.9216rem;
      line-height: 1.5872rem;
      text-align: left;
      color: #04111d;
      margin-bottom: 0.8192rem;
    }

    .uploadNotes {
      font-style: normal;
      font-weight: 400;
      font-size: 0.8192rem;
      line-height: 1.2288rem;
      color: #707a83;
      margin: 0 0 0.6144rem 0;

      & > span {
        display: block;
      }

      .required {
      }
    }

    ::v-deep .uploadImg {
      margin: 0;
      width: 17.66405rem;
      height: 12.80003rem;

      .el-upload {
        width: 17.66405rem;
        height: 12.80003rem;
        background: #ffffff;
        border: 0.1024rem dashed #cccccc;
        border-radius: 0.8192rem;
        position: relative;

        .el-icon-picture {
          &:before {
            display: none;
          }

          width: 2.86721rem;
          height: 2.86721rem;
          background-color: red;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(../../assets/imgs/mint/4.svg) no-repeat center center;
          background-size: 2.86721rem 2.86721rem;
        }
      }
    }

    ::v-deep .form_view {
      margin-top: 0.8192rem;

      .form_view_title {
        font-style: normal;
        font-weight: 500;
        font-size: 0.8192rem;
        line-height: 1.2288rem;
        /* identical to box height, or 150% */
        color: #353840;

        span {
        }
      }

      .form_view_input {
        margin-top: 0.6144rem;

        input {
          max-width: 100%;
          width: 17.66405rem;
          height: 2.56001rem;
          padding: 0 0.6144rem;
          border-radius: 0.512rem;
          font-style: normal;
          font-weight: 400;
          font-size: 0.7168rem;
          line-height: 1.0752rem;
          /* identical to box height */
          color: #8a939b;
        }

        textarea {
          max-width: 100%;
          height: 11.92963rem;
          border-radius: 0.512rem;
          font-style: normal;
          font-weight: 400;
          font-size: 0.7168rem;
          line-height: 1.0752rem;
          /* identical to box height */
          color: #8a939b;
          padding: 0.768rem 0.6144rem;
          resize: none;
        }

        .el-date-editor.el-input {
          width: 17.66405rem;
          height: 2.56001rem;

          input {
            width: 100%;
            height: 100%;
          }
        }
      }

      .form_view_information {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form_view_information_item {
          // font-style: normal;
          margin: 0;

          .information_icon {
            font-size: 0.8192rem;
            display: none;
          }

          .information_text {
            font-weight: 400;
            font-size: 0.768rem;

            line-height: 1.1264rem;
            margin-left: 0.512rem;
            margin: 0;
            border-bottom: 0.1536rem solid transparent;
            padding-bottom: 0.4096rem;
          }

          &.informationCheck {
            .information_text {
              color: #04111d !important;
              border-bottom: 0.1536rem solid #ffc107;
            }
          }
        }
      }

      .form_view_input1 {
        margin-top: 0.6144rem;

        input {
          width: 14.59204rem;
          height: 2.56001rem;
          border-radius: 0.512rem;
          padding: 0 0.6144rem;
          font-size: 0.768rem;
          color: #8a939b;
        }

        .locationClick {
          width: 2.56001rem;
          height: 2.56001rem;
          line-height: 2.56001rem;
          border-radius: 0.4096rem;
          font-size: 0.9216rem;
          margin-left: 0.512rem;
        }
      }

      .form_view_introduce {
        margin-top: 0.6144rem;
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 400;
        font-size: 0.7168rem;
        line-height: 1.1264rem;
        /* or 157% */
        color: #707a83;
      }

      .form_view_tagSingleChoice {
        flex-wrap: wrap;
        justify-content: space-between;

        .tagSingleChoice_li {
          width: 8.44802rem;
          height: 2.56001rem;
          margin: 0 0 0.6144rem 0;
          border-radius: 0.512rem;
          font-style: normal;
          font-weight: 400;
          font-size: 0.7168rem;
          line-height: 2.56001rem;
          /* identical to box height */
          color: #8a939b;
        }
      }

      .Blockchain_select {
        width: 17.66405rem;
        height: 2.56001rem;
        margin: 0.6144rem 0 0;

        .el-icon-arrow-down {
          font-weight: 900;
        }
      }
    }

    .blockchainView {
      margin-top: 0.2048rem;
    }

    .mintCreate_button {
      margin-top: 1.536rem;

      button {
        width: 17.66405rem;
        height: 2.56001rem;
        /*font-family: 'Poppins';*/
        font-style: normal;
        font-weight: 500;
        font-size: 0.7168rem;
        line-height: 1.0752rem;
        /* identical to box height */
        text-align: center;
        color: #b7bdc6 !important;
        font-family: Poppins;

        &.mintCreate_button_true {
          color: #614f00 !important;
        }
      }
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
</style>